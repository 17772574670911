@font-face {
    font-family: 'Satoshi-Variable';
    src: url('../fonts/Satoshi-Variable.ttf') format('truetype');
         font-weight: 300 900;
         font-display: swap;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'Satoshi-Light';
    src: url('../fonts/Satoshi-Light.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'Satoshi-Regular';
    src: url('../fonts/Satoshi-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/Satoshi-Medium.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'Satoshi-Bold';
    src: url('../fonts/Satoshi-Bold.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'Satoshi-Black';
    src: url('../fonts/Satoshi-Black.ttf') format('truetype');
         font-weight: 900;
         font-display: swap;
         font-style: normal;
  }
  